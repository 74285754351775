<template>
  <div class="footer a pt-16 pb-16">
    <div>
        <a href="#yipi" >
          <img class="img-medium" src="../assets/logoyipi2.svg" />
        </a>
    </div>
    <div class="container-social">
      <div>
        <a
          class="flex-social"
          :href="social.link"
          target="_blank"
          rel="noopener noreferrer"
          v-for="social in socials"
          :key="social.id"
        >
          <img
            class="icon-social"
            :src="require(`@/assets/BotonesSVG/${social.icon}`)"
          />
          <div>
            <p class="text-social">{{ social.text }}</p>
            <p class="text-social" v-if="social.secondtext">
              {{ social.secondtext }}
            </p>
          </div>
        </a>
      </div>

      <div class="two-columns" v-for="social in socialsPairs" :key="social.id">
        <a
          class="flex-social"
          :href="item.link"
          v-for="item in social"
          :key="item.id"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            class="icon-social"
            :src="require(`@/assets/BotonesSVG/${item.icon}`)"
          />
          <p class="text-social">{{ item.text }}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    socials: [
      {
        text: "378 110 668",
        icon: "Boton_telefono.svg",
        link: "tel:+52378 110 668",
      },
      {
        text: "Paseo Solares 156, fracionamiento solares.",
        secondtext: "Zapopan, Jalisco. CP: 45019",
        icon: "Boton_direccion.svg",
        link: "https://goo.gl/maps/ZPC3LeeAfBi94f8R9",
      },
    ],
    socialsPairs: [
      {
        icon1: {
          text: "www.yipi.mx",
          icon: "Boton_web.svg",
          link: "https://yipi.mx/",
        },
        icon2: {
          text: "YipiMueveMéxico",
          icon: "Boton_fb.svg",
          link: "https://www.facebook.com/yipimuevemexico/",
        },
      },
      {
        icon1: {
          text: "@yipimx",
          icon: "Boton_insta.svg",
          link: "https://www.instagram.com/yipimx/",
        },
        icon2: {
          text: "@yipidelivery",
          icon: "Boton_insta.svg",
           link: "https://www.instagram.com/yipimx/",
        },
      },
    ],
  }),
};
</script>

<style scoped>
.two-columns {
  display: flex;
}
.container-social {
  display: flex;
  flex-direction: column;
}
.text-social {
  margin: 0;
  font-size: 0.9rem;
  padding-left: 15px;
  font-family: Avenir;
  line-height: 1;
  color: #504e4e;
}

.flex-social {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.icon-social {
  width: 30px;
  height: 30px;
}
.img-medium {
  width: 200px;
  height: 150px;
  margin-right: 80px;
}
.footer {
  background-color: white;
  height: 220px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.a {
  position: absolute;
  left: 0;
  text-decoration: none;
}

@media (max-width: 600px) {
  .icon-social{
    width: 30px;
    height: 25px;
  }
  .img-medium {
    position: absolute;
    left: 10px;
    top: 50px;
    width: 90px;
    height: 90px;
    margin-right: 0px;
  }
  .container-social{
    position: absolute;
    top: 0;
    right: 0;
    
  }
  .text-social {
    margin: 0;
    font-size: 0.6rem;
    padding-left: 5px;
    font-family: Avenir;
    line-height: 1;
    color: #504e4e;
  }
}
</style>